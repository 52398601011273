.ServiceLPaper {
  margin-top: 16px;
  padding: 16px;
}
.ServiceLTitle {
  padding-bottom: 16px;
}
.ServiceLTable {
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border: 1px solid #283b54 !important;
}
.ServiceLTableHead {
  background: #283b54 !important;
  border: 1px solid #283b54;
}
.ItemBold {
  font-weight: bold !important;
  font-size: "35px" !important;
}
.ItemStartBold {
  font-weight: bold !important;
  text-align: start !important;
}
.Start {
  text-align: start !important;
}
.ServiceLMenu {
  font-weight: bold !important;
  border: 1px solid black;
  font-size: 5vw !important;
}
