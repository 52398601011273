.DialogContent {
  padding: 20px !important;
}
.HDialogButton {
  margin-top: 10px !important;
  margin-left: 10px !important;
  text-decoration: none;
}
.HBottomButton {
  display: flex;
  justify-content: flex-end;
}

.VVRow {
  display: flex;
  /* flex: 2; */
  justify-content: flex-start;
  flex-direction: row;
}
.VVObject {
  /* margin-right: 20px !important; */
  width: 40%;
  text-align: left;
}
.VVValue {
  margin-left: 20px !important;
  text-decoration: underline;
  width: 60%;
  text-align: left;
}

.iFrame {
  border: 1px solid black;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
  /* width: 60px; */
}


/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}