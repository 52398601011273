.SLGridPaper {
  margin: 16px;
  padding: 16px;
}

.SearchItem {
  padding-left: 16px;
}
.SLGrid {
  margin-bottom: 16px;
}
.TableStyle {
  border: none;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}
.hyperlinkColor:hover{
  color: blue;
}
.runPrequalButton{
  color: cornflowerblue;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}