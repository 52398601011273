.SalesLPaper {
  margin-top: 16px;
  padding: 16px;
  /* border: none !important; */
}
.SalesLTitle {
  padding-bottom: 16px;
}
.SalesLTable {
  border: 1px solid #283b54 !important;
}

.SalesLTableHead {
  background: #283b54 !important;
  border: 1px solid #283b54;
}
.carsSold {
  font-weight: bold !important;
  /* font-size: 2vw !important;
  border: 1px solid black !important; */
}

.row {
  height: 14px;
}
