.Header {
  display: flex;
  flex-grow: 1;
}
.SalesLine {
  flex-grow: 1;
  cursor: pointer;
}
.Setting {
  display: flex;
  align-items: center;
}
.MR10 {
  margin-right: 24px !important;
}
.AppBar {
  background: #283b54 !important;
}
.Logout {
  cursor: pointer;
}

.P10 {
  margin-top: 10px !important;
}
