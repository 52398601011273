.FDialogContent {
  padding: 20px !important;
}
.FHDialogButton {
  margin-top: 10px !important;
  margin-left: 10px !important;
  text-decoration: none;
}
.FHBottomButton {
  display: flex;
  justify-content: flex-end;
}

.FToPrint {
  margin-top: 16px !important;
  margin-left: 16px !important;
}
