.DialogContent {
  padding: 20px !important;
}

.HDialogButton {
  margin-top: 10px !important;
  margin-left: 10px !important;
  text-decoration: none;
}
.HBottomButton {
  display: flex;
  justify-content: flex-end;
}
.customerRow {
  display: flex;
  flex:1;
  justify-content: center;
  margin-right: 10%;
}
.customerObject {
  width: 35%;
  text-align: left;
}
.customerValue {
  margin-left: 20px !important;
  /* text-decoration: underline; */
  font-weight: bold !important;
  width: 50%;
  text-align: left;
}
