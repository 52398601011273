.DialogContent {
  padding: 20px !important;
}

.Icon {
  font-size: 55px !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}
.Button {
  width: 100%;
  margin-top: 10px !important;
  text-decoration: none;
}
