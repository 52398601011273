.YMMDialogContent {
  padding: 20px !important;
}

.YMMHDialogButton {
  /* margin-top: 10px !important; */
  /* margin-left: 10px !important; */
  text-decoration: none;
}
.YMMHBottomButton {
  display: flex;
  justify-content: flex-end;
}
.YMMRow {
  display: flex;
  flex:1;
  justify-content: center;
  margin-right: 10%;
  /* margin-left: 10%; */
}
.YMMObject {
  /* margin-right: 20px !important; */
  width: 30%;
  text-align: left;
}
.YMMValue {
  margin-left: 20px !important;
  /* text-decoration: underline; */
  font-weight: bold !important;
  width: 50%;
  text-align: left;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
